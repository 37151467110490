
import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import backIcon from '../../assets/images/back.svg'
import ThemeContext from '../../context/ThemeContext';
import HTMLReactParser from 'html-react-parser/lib/index';

function OfferupModal({show, hide, title, children, showGoBack, onChangeContent,goBackContent, ...props}) {
  const {theme, setTheme} = useContext(ThemeContext);
  return (
    <Modal
      show={show}
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={`d-flex justify-content-center p-1 border-0 ${theme === "dark" ? "bg-dark text-light" : ""}`}>
        {showGoBack && <img src={backIcon} alt="back" className='h-24 position-absolute left-1rem cursor-pointer' onClick={() => onChangeContent(goBackContent)} /> }
        <h2 className='fw-boldest modal-title text-center'>{ HTMLReactParser(title)}</h2>
        <CloseButton className='btn-close position-absolute right-1rem' onClick={hide} />
      </Modal.Header>
      <Modal.Body className={theme === "dark" ? "bg-dark text-light" : ""}>
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default OfferupModal;