import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const Sidebar = ({min, max}) => {
  const navigate = useNavigate();
  const { t, i18n: {changeLanguage, language} } = useTranslation();
  const [conditionParams, setConditionParams] = useSearchParams();
  let current_params = conditionParams.get("condition")?.split(",") || []
  // const formRef = useRef()
  // const location = useLocation();


  // const submitForm = (event) => {
  //   event.preventDefault();
  //   const formData=new FormData(formRef.current)
  //       const data=Object.fromEntries(formData)
  //   navigate(`?min=${data.min}&max=${data.max}`)
  // }

  // function fetchCondition() {
    
  // }

  const fetchCondition=(event)=>{
    const {name, value} = event?.target;
    let updated_params = conditionParams.get("condition")?.split(",") || []
    if(event.target.checked) {
      updated_params.push(value);
    }else{
      let index = updated_params.indexOf(value)
      if (index > -1) {
        updated_params.splice(index, 1);
      }
    }
    setConditionParams({[name]: updated_params.join(",")}) 
    window.location.reload();      
  }

  // const onChange=(event)=>{
  //   const {name, value} = event?.target;
  //   navigate({[name]: value})       
  // }

  return (
    <div className='col-2'>
      <div className='card'>
        <div className='card-body p-1 pt-3'>
          <strong>{t('filterTitle')}</strong>
          <br />
          <div className='mt-2'>
            <strong>{t('priceRange')}</strong>
          </div>
          <form >
            <div className='d-flex align-items-center justify-content-between'>
              <input type='number' className='form-control' name='min' placeholder='Min' defaultValue={min}  />
              <span className='mx-2'>to</span>
              <input type='number' className='form-control' placeholder='Max' name='max' defaultValue={max} />
              <button type='submit' className='btn btn-rounded ms-2' style={{border: 'solid 1px green', borderRadius: '20px', color: 'green'}}>Go</button>
            </div>
          </form>
          <hr />
          <div>
            <strong>Condition</strong>
            <div className='d-flex'>
              <input type='checkbox' name='condition' value='new' checked={current_params.includes('new')}  style={{height: '20px', width: '20px'}} onChange={fetchCondition} />
              <div className='ms-2'>
                <strong>New</strong>
                <br />
                <span style={{fontSize: '12px'}}>Unused, sealed box, undamaged</span>
              </div>
            </div>
            <div className='d-flex'>
              <input type='checkbox' name='condition' value='open_box' checked={current_params.includes('open_box')}  style={{height: '20px', width: '20px'}} onChange={fetchCondition} />
              <div className='ms-2'>
                <strong>Open Box</strong>
                <br />
                <span style={{fontSize: '12px'}}>Unused, undamaged</span>
              </div>
            </div>
            <div className='d-flex'>
              <input type='checkbox' name='condition' value='reconditioned' checked={current_params.includes('reconditioned')} style={{height: '20px', width: '20px'}} onChange={fetchCondition} />
              <div className='ms-2'>
                <strong>Reconditioned</strong>
                <br />
                <span style={{fontSize: '12px'}}>Restored by seller or a third party</span>
              </div>
            </div>
            <div className='d-flex'>
              <input type='checkbox' name='condition' value='used'  checked={current_params.includes('used')} style={{height: '20px', width: '20px'}} onChange={fetchCondition} />
              <div className='ms-2'>
                <strong>Used</strong>
                <br />
                <span style={{fontSize: '12px'}}>Works perfectly, may show some signs of wear</span>
              </div>
            </div>
            <div className='d-flex'>
              <input type='checkbox' name='condition' value='for_parts'  checked={current_params.includes('for_parts')} style={{height: '20px', width: '20px'}} onChange={fetchCondition} />
              <div className='ms-2'>
                <strong>For parts</strong>
              </div>
            </div>
            <div className='d-flex'>
              <input type='checkbox' name='condition' value='others' checked={current_params.includes('others')} style={{height: '20px', width: '20px'}} onChange={fetchCondition} />
              <div className='ms-2'>
                <strong>Others</strong>
                <br />
                <span style={{fontSize: '12px'}}>See Item details</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Sidebar;