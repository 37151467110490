

import { useEffect, useState } from "react";

const Confirmation = () => {
  const [error, setError] = useState(null);
  const checkConfirmation = async (token) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/confirmation?confirmation_token=${token}`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json'
        }
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      setError(data.message)
      // localStorage.setItem("token", response.headers.get("Authorization"))
      // setCurrUser(data)  
      // onHide();      
    } catch (error) {
      //  setError(error)
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const confirmationToken = searchParams.get('confirmation_token');
    checkConfirmation(confirmationToken)
  }, []);

  return (
    <div>{error && <h1>{error}</h1>}</div>
  )
};

export default Confirmation;