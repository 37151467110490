import { useEffect, useState, useRef, useContext } from 'react'
import userAvatar from './../../../assets/images/user_avatar.svg'
import linkIcon from './../../../assets/images/external-link.svg'
import OfferupModal from '../../Modals/OfferupModal';
import UpdateName from './UpdateName';
import UpdateEmail from './UpdateEmail';
import UpdateLocation from './UpdateLocation';
import UpdatePhone from './updatePhone';
import VerifyPhone from '../../Auth/VerifyPhone';
import VerifyOtp from '../../Auth/VerifyOtp';
import ForgotPassword from '../../Auth/ForgotPassword';
import ThemeContext from '../../../context/ThemeContext';
import { Link } from 'react-router-dom';

const Payments = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [imageURL, setImageUrl] = useState(userAvatar);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState('');
  const imgInputRef = useRef();
  const ImgformRef = useRef();
  // const [theme, setTheme] = useState(localStorage.getItem('theme') || "light");
  let showGobackBtn = false;
  let modalTitle = '';
  let goBackContent = '';
  let modalContent = '';

  if (content === "changeName") {
    modalTitle = "Update Name"
    modalContent = <UpdateName onHide={hideModal} setUser={setUser} />
  } else if (content === "changeEmail") {
    modalTitle = "Update Email"
    modalContent = <UpdateEmail onHide={hideModal} setUser={setUser} />
  } else if (content === "changeLocation") {
    modalTitle = "Update Location"
    modalContent = <UpdateLocation onHide={hideModal} setUser={setUser} />
  } else if (content === "changePhone") {
    // onChangeContent('verify_phone');
    modalTitle = "Update Phone"
    modalContent = <VerifyPhone onChangeContent={handleSetContent} onHide={hideModal} />
  } else if (content === "verify_otp") {
    modalTitle = 'Verify OTP';
    modalContent = <VerifyOtp onChangeContent={handleSetContent} onHide={hideModal} setCurrUser={setUser} />
  } else if (content === "changePassword") {
    modalTitle = 'Forgot Password';
    modalContent = <ForgotPassword onHide={hideModal} />
  }

  const getText = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/settings`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setUser(data)
      if (data.avatar_url) {
        setImageUrl(data.avatar_url)
      }
    }
    catch (error) {
      // setUser(null);
    }
  }

  const updateAvatar = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('user[avatar]', image)
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/update-avatar`, {
        method: "put",
        headers: {
          // "content-type": "application/json", //commenting this is important when submitting form with image
          "authorization": localStorage.getItem("token")
        },
        body: formData
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setUser(data)
    }
    catch (error) {
      // setUser(null);
    }
  }

  useEffect(() => {
    getText()
  }, [])

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageUrl(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  }

  function hideModal() {
    setShowModal(false);
    setContent('');
  }

  function handleSetContent(content) {
    setShowModal(true);
    setContent(content);
  }

  function changeTheme(theme) {
    setTheme(theme);
    localStorage.setItem('theme', theme);
  }

  return (
    <>
      {showModal &&
        <OfferupModal
          showGoBack={showGobackBtn}
          show={showModal}
          hide={hideModal}
          title={modalTitle}
          goBackContent={goBackContent}
          onChangeContent={handleSetContent}
        >
          {modalContent}
        </OfferupModal>
      }
      <div className="container mt-3">
        <div className='d-flex gap-2'>
          <Link to={"/"}>Home</Link>
          <span>›</span>
          <Link to={"/account-settings"}>Account</Link>
          <span>›</span>
          <span>Account Settings</span>
        </div>
        <div className="row mt-3">
          <div className='col-3 border py-3' style={{ padding: 0 }}>
            <h2 className='mx-3'>Account</h2>
            <h5 className='mx-3'>Transaction</h5>
            <ul className='list-group' style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              <li className='list-group-item' style={{ padding: '8px 15px', fontSize: '0.875rem', border: 'none', cursor: 'pointer' }}>
                <Link to={"/accounts/transactions"} style={{ textDecoration: 'none', color: 'black' }}>Purchases & Sales</Link>
              </li>
              <li className='list-group-item' style={{ padding: '8px 15px', fontSize: '0.875rem', border: 'none', cursor: 'pointer' }}>
                <Link to={"/accounts/payments"} style={{ textDecoration: 'none', color: 'black' }}>Payment & Deposit methods</Link>
              </li>
            </ul>
            <h5 className='mx-3'>Saves</h5>
            <ul className='list-group' style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              <li className='list-group-item' style={{ padding: '8px 15px', fontSize: '0.875rem', border: 'none', cursor: 'pointer' }}>
                <Link to={"/saved_products"} style={{ textDecoration: 'none', color: 'black' }}>Saved Items</Link>
              </li>
            </ul>
            <h5 className='mx-3'>Account</h5>
            <ul className='list-group' style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              <li className='list-group-item' style={{ padding: '8px 15px', fontSize: '0.875rem', border: 'none', cursor: 'pointer' }}>
                <Link to={"/account-settings"} style={{ textDecoration: 'none', color: 'black' }}>Account Settings</Link>
              </li>
            </ul>
            <div className='mx-3'>
              <Link to={`/user/${user?.id}`} style={{ textDecoration: 'underline', color: 'black', display: 'flex', alignItems: 'center' }}>
                <span>View public profile</span>
                <img src={linkIcon} alt='link' style={{ height: '20px', width: '20px' }} />
              </Link>
            </div>
          </div>
          <div className="col-8">
            <h1>Payment & Deposit methods</h1>
            <div className='card'>
              <div className='d-flex gap-4 px-5 pt-4 pb-2'>
                <div className='px-4'>
                  <Link to={"/accounts/transactions"} style={{ textDecoration: 'none' }}><span>Accounts</span></Link>
                </div>
                <div style={{ borderBottom: '3px solid rgb(16, 179, 110)' }}>
                  <Link to={"/accounts/payments"} style={{ textDecoration: 'none' }}><span>Transaction</span></Link>
                </div>
              </div>
              <div style={{ backgroundColor: '#f7f7f7' }}>
                <div className='m-4 mb-0 border border-bottom p-3' style={{ backgroundColor: "#fff" }}>
                  <strong>Balance</strong>
                </div>
                <div className='m-4 mt-0 border p-3' style={{ backgroundColor: "#fff" }}>
                  <span>$0.00</span>
                </div>


                <div className='m-4 mt-0 border p-3 mt-5 d-flex justify-content-between' style={{ backgroundColor: "#fff" }}>
                  <strong>Payment Methods</strong>
                  <strong className='text-green'>Add Credit/Debit Card</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Payments;