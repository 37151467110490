
import { useRef, useState } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

function Signup({ setCurrUser, setShow, onHide, showAlert }) {
  const formRef = useRef()
  const [error, setError] = useState(null);
  const signup = async (userInfo, setCurrUser) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/signup`
    try {
      const response = await fetch(url, {
        method: 'post',
        headers: {
          "content-type": 'application/json',
          "accept": "application/json"
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      // localStorage.setItem('token', response.headers.get("Authorization"))
      // setCurrUser(data)
      onHide();
      MySwal.fire({
        title: 'An Email with Confirmation Link is Sent your email, please confirm '
      })
    } catch (error) {
      // setError(error)
    }
  }
  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { email: data.email, password: data.password, name: data.name }
    }
    signup(userInfo, setCurrUser)
    // e.target.reset()
  }
  const handleClick = e => {
    e.preventDefault()
    setShow(true)
  }

  return (
    <>
      {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="mt-2">
          <label className='fw-medium'>Name</label>
          <br />
          <input type='text' name='name' autoFocus={true} className='form-control w-100' />
          <span className="feedback"></span>
        </div>
        <div className="mt-2">
          <label className='fw-medium'>Email</label>
          <br />
          <input type='email' name='email' className='form-control w-100' />
          <span className="feedback"></span>
        </div>
        <div className="mt-2">
          <label className='fw-medium'>Password</label>
          <br />
          <input type='password' name='password' className='form-control w-100' />
          <span className="feedback"></span>
        </div>
        <div className="text-center mt-3" style={{ color: '#505050', fontSize: '0.9375rem', fontWeight: 400, textAlign: 'center' }}>
          <p>By tapping "Agree & Sign up" you agree to OfferUp's Terms of Service and acknowledge the Privacy Policy.</p>
        </div>
        <div className="actions">
          <button type="submit" className='btn btn-outline-light border border-1 w-100 bg-green rounded-pill position-relative text-white fw-medium'>Agree & Sign up</button>
        </div>
      </form>
    </>
  );
}

export default Signup;