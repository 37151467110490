
import { useRef, useState } from "react"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

function Settings({ onHide, user, setUser, description, field_name, showEmail, showPush }) {
  const formRef = useRef();
  const [error, setError] = useState(null);
  const [emailMsg, setEmailMessage] = useState(user[`${field_name}_email`] ? null : 'You will not receive emails');
  const [pushMsg, setPushMsg] = useState(user[`${field_name}_push`] ? null : 'You will not receive push notifications');

  const changeSettings = async (event) => {
    event.preventDefault();
    let name = event.target.name;
    let isChecked = event.target.checked;
    if (!isChecked && name.indexOf('email') > 0) {
      setEmailMessage('You will not receive emails')
    } else {
      setEmailMessage(null)
    }
    if (!isChecked && name.indexOf('push') > 0) {
      setPushMsg('You will not receive push notifications')
    } else {
      setPushMsg(null)
    }
    let FormData = {
      name: name,
      value: isChecked
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/update-settings`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "Authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(FormData)
      })
      const data = await response.json()
      if (!response.ok) {
        throw data.message
      }
      setUser(data.user)
      onHide();
      MySwal.fire({
        title: data.message
      })
    } catch (error) {
      setError(error)
    }
  }

  return (
    <>
      {
        showEmail ? <div>
          <p>{description}</p>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span>Email</span>
              <br />
              <span style={{ color: 'grey' }}>{emailMsg}</span>
            </div>
            <div class="form-check form-switch">
              <input className="form-check-input cursor-pointer" style={{ height: '30px', width: '60px' }} name={`${field_name}_email`} type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={changeSettings} checked={user[`${field_name}_email`]} />
            </div>
          </div>
        </div>
          : ''
      }
      {
        showPush ? <div>
          <div className="d-flex justify-content-between mt-3">
            <span>
              <span>Push Notification</span>
              <br />
              <span style={{ color: 'grey' }}>{pushMsg}</span>
            </span>
            <div class="form-check form-switch">
              <input className="form-check-input cursor-pointer" name={`${field_name}_push`} style={{ height: '30px', width: '60px' }} type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={changeSettings} checked={user[`${field_name}_push`]} />
            </div>
          </div>
        </div>
          : ''
      }
    </>
  );
}

export default Settings;