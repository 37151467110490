import { useEffect, useState, useRef, useContext } from 'react'
import userAvatar from './../../../assets/images/user_avatar.svg'
import linkIcon from './../../../assets/images/external-link.svg'
import OfferupModal from '../../Modals/OfferupModal';
import UpdateName from './UpdateName';
import UpdateEmail from './UpdateEmail';
import UpdateLocation from './UpdateLocation';
import UpdatePhone from './updatePhone';
import VerifyPhone from '../../Auth/VerifyPhone';
import VerifyOtp from '../../Auth/VerifyOtp';
import ForgotPassword from '../../Auth/ForgotPassword';
import ThemeContext from '../../../context/ThemeContext';
import { Link } from 'react-router-dom';

const Inbox = () => {
  const [messages, setMessages] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [messageLoader, setMessageLoader] = useState(true);
  const [notificationLoader, setNotificationLoader] = useState(true);

  const { theme, setTheme } = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [imageURL, setImageUrl] = useState(userAvatar);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState('');
  const imgInputRef = useRef();
  const ImgformRef = useRef();
  // const [theme, setTheme] = useState(localStorage.getItem('theme') || "light");
  let showGobackBtn = false;
  let modalTitle = '';
  let goBackContent = '';
  let modalContent = '';

  if (content === "changeName") {
    modalTitle = "Update Name"
    modalContent = <UpdateName onHide={hideModal} setUser={setUser} />
  } else if (content === "changeEmail") {
    modalTitle = "Update Email"
    modalContent = <UpdateEmail onHide={hideModal} setUser={setUser} />
  } else if (content === "changeLocation") {
    modalTitle = "Update Location"
    modalContent = <UpdateLocation onHide={hideModal} setUser={setUser} />
  } else if (content === "changePhone") {
    // onChangeContent('verify_phone');
    modalTitle = "Update Phone"
    modalContent = <VerifyPhone onChangeContent={handleSetContent} onHide={hideModal} />
  } else if (content === "verify_otp") {
    modalTitle = 'Verify OTP';
    modalContent = <VerifyOtp onChangeContent={handleSetContent} onHide={hideModal} setCurrUser={setUser} />
  } else if (content === "changePassword") {
    modalTitle = 'Forgot Password';
    modalContent = <ForgotPassword onHide={hideModal} />
  }

  const getText = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/settings`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setUser(data)
      if (data.avatar_url) {
        setImageUrl(data.avatar_url)
      }
    }
    catch (error) {
      // setUser(null);
    }
  }

  const updateAvatar = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('user[avatar]', image)
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/update-avatar`, {
        method: "put",
        headers: {
          // "content-type": "application/json", //commenting this is important when submitting form with image
          "authorization": localStorage.getItem("token")
        },
        body: formData
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setUser(data)
    }
    catch (error) {
      // setUser(null);
    }
  }

  useEffect(() => {
    getText()
  }, [])

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageUrl(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  }

  function hideModal() {
    setShowModal(false);
    setContent('');
  }

  function handleSetContent(content) {
    setShowModal(true);
    setContent(content);
  }

  async function fetchMessages() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/messages`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setMessageLoader(false);
      setMessages(data)
      if (data.avatar_url) {
        // setImageUrl(data.avatar_url)
      }
    }
    catch (error) {
      // setUser(null);
    }
  }

  useEffect(() => {
    fetchMessages();
  })

  return (
    <>
      <div className="container mt-3">
        <div className='d-flex gap-2'>
          <Link to={"/"}>Home</Link>
          <span>›</span>
          <span>Inbox</span>
        </div>
        <div className="col-12">
          <h1>Inbox</h1>
          <div className='card' id=''>
            <ul class="nav nav-pills mb-3 d-flex " id="pills-tab" role="tablist" style={{ borderBottom: 'solid 1px #e4e4e4' }}>
              <li class="nav-item" role="presentation">
                <div class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Messages</div>
              </li>
              <li class="nav-item" role="presentation">
                <div class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Notifications</div>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active mx-2" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                {
                  messageLoader && <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                }
                {
                  messages.length === 0 ? <span>No Messages</span> : <div>
                    <div className='border-1 border-bottom d-flex align-items-center gap-2'>
                      <input type="checkbox" style={{ height: '25px', width: '25px' }} />
                      <button className='btn btn-outline-light border border-1 bg-green rounded-pill position-relative text-white fw-medium' disabled>Delete</button>
                    </div>
                    {
                      messages.map(function (message) {
                        return <div className="d-flex justify-content-between align-items-start gap-2 border-bottom my-2">
                          <div className='d-flex gap-2 align-items-center'>
                            <input type="checkbox" style={{ height: '25px', width: '25px' }} />
                            <img style={{ height: '50px', width: '50px', borderRadius: '50%' }} src={message.sender_avatar_url ? message.sender_avatar_url : userAvatar} alt="user avatar" />
                          </div>
                          <div style={{ flex: 1 }}>
                            <strong>{message.sender}</strong>
                            <br />
                            <span>{message.message}</span>
                            <br />
                            <span style={{ color: 'gray', fontSize: '13px' }}>{message.created_at}</span>
                          </div>
                          <img style={{ height: '50px', width: '50px' }} src={message.product_image} alt="product img" />
                        </div>
                      })
                    }
                    {/* <Link to={"/inbox"} style={{textDecoration: 'none'}}><strong className="text-green">View all messages</strong></Link> */}
                  </div>
                }
              </div>
              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Inbox;