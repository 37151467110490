import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';


const ListProducts = ({products, fetchProducts, hasMore}) => {
  let content = '';
  if(products.length === 0) {
    content = <h1>No Products to show</h1>
  }else {
    content =  <InfiniteScroll
      dataLength={products.length}
      next={fetchProducts}
      hasMore={hasMore}
      loader={<div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            }
      endMessage={<p>End of Products</p>}
      className={"row"}
    >
      {products.map((product) => (
        <div className="col-2 mt-2" key={product.id}>
          <Link to={`/product/${product.id}`}>
            <div className="card border-0">
              <img src={product.main_image} alt={product.title} style={{height: '150px', width: '100%'}} />
              <div className="mx-2">
                <strong className="my-1" style={{display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{product.title}</strong>
                <span>${product.price}</span>
                <br />
                <strong style={{color: "rgb(164 164 164)"}}>{product.city}</strong>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </InfiniteScroll>
  }
  return (
    <div className="row">
      {
       content 
      }
    </div>
  )
};


export default ListProducts;