
import { useRef, useState } from "react"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import OfferupModal from "../Modals/OfferupModal";

const MySwal = withReactContent(Swal);

function AskOffers({ onHide, product }) {
  const formRef = useRef();
  const messageRef = useRef();
  const [error, setError] = useState(null);

  const sendAskMessage = async (event, message) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_URL}/messages/ask-message`
    let formData = {
      message: message,
      product_id: product.id,
      receiver_id: ""
    }
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(formData)
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      onHide();
      Swal.fire({
        title: "Message Sent"
      })
    } catch (error) {
      //  setError(error)
      Swal.fire({
        title: "Error Sending Message"
      })
    }
  }

  return (
    <>
      <strong>Click a message to send or write your own:</strong>
      <div>
        <button className="btn btn-outline-light text-green rounded-pill position-relative mt-3" style={{ border: 'solid 1px #00a87e' }}>
          <span className="text-green fw-medium" onClick={(event) => sendAskMessage(event, 'Hi, is this still available?')}>Hi, is this still available?</span>
        </button>
      </div>
      <div>
        <button className="btn btn-outline-light text-green rounded-pill position-relative mt-3" style={{ border: 'solid 1px #00a87e' }}>
          <span className="text-green fw-medium" onClick={(event) => sendAskMessage(event, 'Hi, i would like to buy this')}>Hi, i would like to buy this</span>
        </button>
      </div>
      <div>
        <button className="btn btn-outline-light text-green rounded-pill position-relative mt-3" style={{ border: 'solid 1px #00a87e' }}>
          <span className="text-green fw-medium" onClick={(event) => sendAskMessage(event, 'Hi, can you meet today?')}>Hi, can you meet today?</span>
        </button>
      </div>
      <div>
        <button className="btn btn-outline-light text-green rounded-pill position-relative mt-3" style={{ border: 'solid 1px #00a87e' }}>
          <span className="text-green fw-medium" onClick={(event) => sendAskMessage(event, 'Will you ship through Offerup?')}>Will you ship through Offerup?</span>
        </button>
      </div>

      <div className="mt-3">
        <strong>New Message</strong>
        <textarea ref={messageRef} className="form-control" rows={4}></textarea>
        <button onClick={(event) => sendAskMessage(event, messageRef.current.value)} className='btn btn-outline-light border border-1 w-100 bg-green rounded-pill position-relative text-white fw-medium mt-3'>Send</button>
      </div>
    </>
  );
}

export default AskOffers;