
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import React from 'react';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Conditions from "./conditions";
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import avatar from '../../assets/images/user_avatar.svg'
import outlineStar from '../../assets/images/star-outline.svg'


import heartIcon from '../../../src/assets/images/heart.svg';
import heartFilledIcon from '../../../src/assets/images/heart-filled.svg';
import shareIcon from '../../../src/assets/images/share.svg';
import shareIconBlack from '../../../src/assets/images/share-black.svg';
import heartIconBlack from '../../../src/assets/images/heart-black.svg';
import heartFilledBlack from '../../../src/assets/images/heart-filled-black.svg';
import flagIconBlack from '../../../src/assets/images/flag-black.svg';
import OfferupModal from '../Modals/OfferupModal';
import AskOffers from './AskOffers';
import MakeOffer from './MakeOffer';

const MySwal = withReactContent(Swal);

const ShowProduct = ({ currentUser }) => {
  const { id } = useParams();
  let savedProduct = currentUser && currentUser.saved_products && currentUser.saved_products.indexOf(parseInt(id)) !== -1;
  const [error, setError] = useState(null);
  const [product, setProduct] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [saved, setIsSaved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const { id } = useParams();
  const [content, setContent] = useState('');
  const [offerType, setOfferType] = useState('');

  let showGobackBtn = false;
  let modalTitle = '';
  let goBackContent = '';
  let modalContent = '';

  // modalTitle = content.title;
  if (offerType === 'ask_offer') {
    modalTitle = "Send a Message";
    modalContent = <AskOffers product={product} onHide={hideModal} />
  } else if (offerType === 'make_offer') {
    if (product.firm_on_price) {
      modalTitle = "Review your offer";
    } else {
      modalTitle = "Send a Message";
    }
    modalContent = <MakeOffer product={product} onHide={hideModal} />
  }
  const getProduct = async (productId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${productId}`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json'
        }
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      setProduct(data)
      setIsSaved(savedProduct);
      //setIsSaved(data.saved);
      setCurrImage(data.photos[0]);
      // navigator.geolocation.getCurrentPosition((position) => {

      // })

      var container = L.DomUtil.get('map');
      if (container != null) {
        container._leaflet_id = null;
      }
      let map = L.map('map').setView([data.lat, data.lng], 15);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
        maxZoom: 18,
      }).addTo(map);
      var circle = L.circle([data.lat, data.lng], {
        color: 'none',
        fillColor: '#8dfa28',
        fillOpacity: 0.5,
        radius: 200
      }).addTo(map);

      // localStorage.setItem("token", response.headers.get("Authorization"))
      // setCurrUser(data)  
      // onHide();
    } catch (error) {
      //  setError(error)
    }
  }

  const copyProductLink = async (productId) => {
    try {
      await navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL}/product/${productId}`);
      MySwal.fire({
        title: 'Link Copied to clipboard!!!'
      })
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }


  const saveProduct = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${product.id}/add-to-favourite`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      setIsSaved(data.status === 'added')
      MySwal.fire({
        title: 'Product added to saved list'
      })
    } catch (error) {
      //  setError(error)
    }
  }

  // const incrementProductViewCount=async (product_id)=> {
  //   const url=`${process.env.REACT_APP_BACKEND_URL}/products/${product_id}/increment-product-view-count`
  //   try{
  //       const response=await fetch(url, {
  //           method: "get",
  //           headers: {
  //               'content-type': 'application/json',
  //               'accept': 'application/json',
  //           }
  //       })
  //       const data=await response.json()
  //       if(!response.ok){
  //         setError(data.message)
  //         throw data.error
  //       }    
  //       setIsSaved(data.status === 'added')
  //   }catch(error){
  //     //  setError(error)
  //   }
  // }

  // async function askOffer() {
  //   setShowModal(true);
  // }

  function hideModal() {
    setShowModal(false);
    setContent('');
  }

  function handleSetContent(content) {
    setShowModal(true);
    setContent(content);
  }

  function showOfferupModal(type) {
    if (currentUser) {
      setOfferType(type);
      setShowModal(true)
    } else {
      MySwal.fire({
        title: 'Please sign in to send message'
      });
    }
  }

  useEffect(() => {
    getProduct(id);
    // incrementProductViewCount(id);
  }, [id]);

  return (
    <>
      {showModal &&
        <OfferupModal
          showGoBack={showGobackBtn}
          show={showModal}
          hide={hideModal}
          title={modalTitle}
          goBackContent={goBackContent}
          onChangeContent={handleSetContent}
        >
          {modalContent}
        </OfferupModal>
      }
      <div className="container mt-5">
        <div className="row">
          <div className="col-8">
            <div className="d-flex justify-content-center" style={{ backgroundColor: '#f2f2f2', border: 'solid 1px #d3d0d0', borderRadius: '10px' }}>
              <img src={currImage} alt={product?.title} style={{ height: '500px', width: '500px' }} />
            </div>
            <div className="d-flex align-items-center gap-3 mt-2">
              {
                product && product.photos.map(photo => {
                  let classes = ""
                  if (photo === currImage) {
                    classes += ' active-img '
                  } else {
                    classes += ' non-active-img '
                  }
                  return <img
                    key={photo}
                    className={classes}
                    src={photo}
                    alt={product?.title}
                    style={{ height: '50px', width: '50px', borderRadius: '10px' }}
                    onClick={() => setCurrImage(photo)}
                  />
                })
              }
            </div>
            <hr />
            <h3 style={{ fontWeight: '700' }}>Details</h3>
            {product?.description && <div>
              <strong>Description</strong>
              <br />
              <span>{product?.description || "N/A"}</span>
            </div>
            }
            <table>
              <tbody>
                {product?.size_type && <tr>
                  <td><strong>Size Type</strong></td>
                  <td style={{ paddingLeft: '20px' }}><span>{product?.size_type || "N/A"}</span></td>
                </tr>
                }
                {
                  product?.brand && <tr>
                    <td><strong>Brand</strong></td>
                    <td style={{ paddingLeft: '20px' }}><span>{product?.brand || "N/A"}</span></td>
                  </tr>
                }
              </tbody>
            </table>
            <hr />
            <div className='d-flex align-items-center gap-4'>
              <div onClick={saveProduct} className='d-flex gap-1 align-items-center' style={{ backgroundColor: '#e4e4e4', padding: '3px 5px', borderRadius: '20px' }}>
                <img src={heartIconBlack} alt='heart' style={{ height: '12px', width: '12px' }} />
                <span style={{ fontSize: '12px' }}>Save</span>
              </div>
              <div className='d-flex gap-1 align-items-center' style={{ backgroundColor: '#e4e4e4', padding: '3px 5px', borderRadius: '20px' }}>
                <img src={flagIconBlack} alt='heart' style={{ height: '12px', width: '12px' }} />
                <span style={{ fontSize: '12px' }}>Report</span>
              </div>
              <div className='d-flex gap-1 align-items-center' style={{ backgroundColor: '#e4e4e4', padding: '3px 5px', borderRadius: '20px' }}>
                <img src={shareIconBlack} alt='heart' style={{ height: '12px', width: '12px' }} />
                <span style={{ fontSize: '12px' }}>Share</span>
              </div>
            </div>
            <hr />
            <div id="map" style={{ height: '200px', width: '100%' }}></div>
            <p className='mt-2' style={{ color: 'rgb(152 149 149)', fontSize: '12px' }}>Map is approximate to keep seller's location private</p>
            <div >
              <h4 style={{ fontWeight: '800' }}>Similar Products</h4>
              <div className='d-flex gap-4'>
                {
                  product && product.similar_products.map(sp => {
                    let [id, url] = sp
                    return <Link to={`/product/${id}`}>
                      <img src={url} alt={sp.title} style={{ height: '100px', width: '100px' }} />
                    </Link>
                  })
                }
              </div>
            </div>
          </div>
          <div className="col-4">
            <h2 style={{ fontWeight: '700' }}>{product?.title}</h2>
            <br />
            <h1 style={{ fontWeight: '800' }}>${product?.price}</h1>
            <div>
              <p>Posted {product?.time_posted} ago in {product?.city}</p>
              <p>Condition: {Conditions[product?.condition]}</p>
              <p>{product?.category_name} - {product?.sub_category_name}</p>
            </div>
            <button onClick={() => showOfferupModal('make_offer')} className='btn btn-outline-light border border-1 w-100 bg-green rounded-pill position-relative text-white fw-medium'>Make offer</button>
            <button onClick={() => showOfferupModal('ask_offer')} className="btn btn-outline-light w-100 text-green rounded-pill position-relative mt-4" style={{ border: 'solid 1px #00a87e' }}>
              <span className="text-green fw-medium">Ask</span>
            </button>
            <hr style={{ margin: '20px 0px' }} />
            <div className='row'>
              <div className='col-6'>
                <div className='d-flex justify-content-center'>
                  <div className='d-flex gap-2 align-items-center' style={{ 'cursor': 'pointer' }} onClick={saveProduct}>
                    <img src={saved ? heartFilledIcon : heartIcon} style={{ width: '24px', height: '24px' }} alt='heart icon' />
                    <span className='text-green'>Save</span>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='d-flex justify-content-center'>
                  <div className='d-flex gap-2 align-items-center' style={{ 'cursor': 'pointer' }} onClick={() => copyProductLink(product?.id)}>
                    <img src={shareIcon} style={{ width: '24px', height: '24px' }} alt='share icon' />
                    <span className='text-green'>Share</span>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ margin: '20px 0px' }} />
            <Link to={`/user/${product?.user_id}`}>
              <div className='row'>
                <div className='d-flex'>
                  <img src={product?.user_avatar_url ? product?.user_avatar_url : avatar} alt='avatar' style={{ height: '70px', width: '70px' }} />
                  <div className='ms-2 d-flex flex-column'>
                    <span className='fw-bold'>{product?.user}</span>
                    <span className='d-flex align-items-center gap-1'>
                      <span className='fw-bold fs-5'>0</span>
                      <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                      <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                      <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                      <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                      <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
};

export default ShowProduct;