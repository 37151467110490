
import { useRef } from 'react';
import offerup from './../../assets/images/offerup.svg'

const VerifyPhone = function ({ show, hide, onChangeContent, onHide }) {
  const formRef = useRef();

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { phone: data.phone }
    }
    sendCode(userInfo)
    // login(userInfo, setCurrUser)
    // e.target.reset()
  }

  function handleSkip(event) {
    event.preventDefault();
    skipPhone();
  }

  const skipPhone = async (userInfo) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/skip-phone`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      onHide();
    } catch (error) {
      //  setError(error)
    }
  }

  const sendCode = async (userInfo) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/send-otp`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      // localStorage.setItem("token", response.headers.get("Authorization"))
      // setCurrUser(data)
      if (data.phone === "" || data.phone == null) {
        onChangeContent('verify_otp')
      } else {
        onHide();
      }
    } catch (error) {
      //  setError(error)
    }
  }

  return (
    <form ref={formRef}>
      <div className="d-flex justify-content-center dddd">
        <img src={offerup} alt='offerup logo' />
      </div>
      <div className="mt-4">
        <div className="mt-2">
          <label className="fw-medium">Phone</label>
          <input type="text" name="phone" className="form-control w-100" autoFocus={true} required />
        </div>

        <div class="actions mt-2 gap-3 d-flex">
          <button type="button" className="btn btn-outline-light border border-1 w-100 text-green rounded-pill" onClick={handleSkip} >Skip</button>
          <button type="submit" className="btn btn-outline-light border border-1 w-100 bg-green rounded-pill position-relative text-white fw-medium" onClick={handleSubmit}>Send Code</button>
        </div>
      </div>
    </form>
  )
}

export default VerifyPhone;