
import { useRef, useState } from "react"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

function UpdateLocation({ onHide, setUser }) {
  const formRef = useRef();
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(null);
  const [loader, showLoader] = useState(false);
  const [latlng, setLatLng] = useState(null);
  const zipRef = useRef();
  const latRef = useRef();
  const lngRef = useRef();


  const updateData = async () => {
    if (!latlng) {
      Swal.fire({
        title: 'Location not selected'
      })
      return;
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/update-location`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "Authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(latlng)
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.message
      }
      setUser(data.user)
      onHide();
      MySwal.fire({
        title: data.message
      })
    } catch (error) {
      setError(error)
    }
  }

  // const handleSubmit=e=>{
  //   e.preventDefault()
  //   const formData=new FormData(formRef.current)
  //   const data=Object.fromEntries(formData)
  //   const userInfo={
  //     "user":{ name: data.name}
  //   }
  //   updateData(userInfo)
  //   e.target.reset()
  // }

  const getLocationByZipCode = () => {
    showLoader(true);
    let zip = zipRef.current.value;
    const fetchNearestAddress = async (zip) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-zip-code?zip=${zip}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        if (!data.cidade) {
          MySwal.fire({
            title: 'Could not get location by zip code, please check details and try again'
          })
          showLoader(false);
          return;
        }
        // let  data = {
        //   latitude: 74.2757747,
        //   longitude: 31.4616912
        // }
        setAddress(data);
        setLatLng({
          lat: data.latitude,
          lng: data.longitude
        })
        // setAddress('lat', data.latitude);
        // setAddress('lng', data.longitude);
        showLoader(false);
      } catch (error) {
        setError(error.message);
      }
    }

    fetchNearestAddress(zip);
  }

  const getLatLngLocation = () => {
    const fetchNearestAddress = async (latitude, longitude) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-lat-lng?lat=${latitude}&lng=${longitude}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        if (!data.cidade) {
          MySwal.fire({
            title: 'Could not get location, please refresh and try again'
          })
          showLoader(false);
          return;
        }
        setAddress(data);
        // let  data = {
        //   latitude: 74.2757747,
        //   longitude: 31.4616912
        // }
        setLatLng({
          lat: data.latitude,
          lng: data.longitude
        })
        // setAddress('lat', data.latitude);
        // setAddress('lng', data.longitude);

        // setData('city', data.cidade.nome)
        showLoader(false);
      } catch (error) {
        setError(error.message);
      }
    }

    const getCurrentLocation = () => {
      showLoader(true)
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            // setCurrentLocation({ latitude, longitude });
            fetchNearestAddress(latitude, longitude);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    }

    getCurrentLocation();
  }

  return (
    <>
      {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
      <div className="card-header">
        <input type="hidden" ref={latRef} name="product[lat]" value={address?.latitude} />
        <input type="hidden" ref={lngRef} name="product[lng]" value={address?.longitude} />
      </div>
      <div className="card-body">
        <div class="d-flex align-items-center justify-content-center">
          <button type="button" class="btn btn-rounded ms-2" style={{ 'border': '1px solid green', 'borderRadius': '20px', 'color': 'green' }} onClick={getLatLngLocation}>Get Current Location</button>
        </div>
        <div class="d-flex align-items-center justify-content-center mt-2">
          <strong>OR</strong>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div className="form-group mt-2">
            <label className="fw-bold">Search By Zip Code</label>
            <div className="d-flex gap-2 align-items-center">
              <input type="text" ref={zipRef} className="form-control" name="product[zipcode]" placeholder="Zip Code" />
              <button type="button" className="btn btn-success" onClick={getLocationByZipCode}>Get</button>
            </div>
          </div>
        </div>
        <div>
          {loader &&
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          }
        </div>
        <hr />
        {address && <div><strong>Your Location: <span style={{ color: 'red' }}>{address.cidade.nome}</span></strong></div>}
        <div className="actions mt-3">
          <button type="submit" className="btn btn-outline-light border border-1 w-100 bg-green rounded-pill position-relative text-white fw-medium" onClick={updateData}>Update Location</button>
        </div>
      </div>
    </>
  );
}

export default UpdateLocation;