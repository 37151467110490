
import { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
const Categories = ({categories}) => {
  const {theme, setTheme} = useContext(ThemeContext);

  return (
    <div>
      <div className={`d-flex align-items-start justify-content-between`}>
        { categories && categories.map((category) => {
            return (
              <div style={{maxWidth: '224px'}} className="offer-body-2" key={category.id}>
                <div className="py-8 px-2" style={{fontSize: '14px'}}>
                  <span>{category.name}</span>
                </div>
                <div className="list">
                  <div className={`${theme === "dark" ? "bg-dark text-light" : ""}`} style={{display: 'flex', alignItems: 'start', flexDirection: 'column', gap: '5px', padding: '0px 5px'}}>
                    { category &&
                      category.sub_categories.map((sub_category) =>{
                        return(
                          <div className="item" key={sub_category.id}>
                            { sub_category.name }
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
   </div>
  )
};


export default Categories;