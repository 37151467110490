

import { useRef, useState, useEffect } from "react";

const ResetPassword = () => {
  // const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const resetToken = searchParams.get('reset_password_token');

  const reset_password = async (userInfo) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/password`
    try {
      const response = await fetch(url, {
        method: 'put',
        headers: {
          "content-type": 'application/json',
          "accept": "application/json"
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      window.location.href = "/";
    } catch (error) {
      // setError(error)
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { password: data.password, password_confirmation: data.confirm_password, reset_password_token: resetToken }
    }
    reset_password(userInfo)
  }

  const formRef = useRef();
  return (
    <div className="row">
      <div className="col-4 offset-4">
        <h1>Reset Password</h1>
        {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="mt-2">
            <label className='fw-medium'>New Password</label>
            <br />
            <input type='password' name='password' className='form-control w-100' />
            <span className="feedback"></span>
          </div>
          <div className="mt-2">
            <label className='fw-medium'>Confirm New Password</label>
            <br />
            <input type='password' name='confirm_password' className='form-control w-100' />
            <span className="feedback"></span>
          </div>
          <div className="actions mt-3">
            <button type="submit" className='btn btn-outline-light border border-1 w-100 bg-green rounded-pill position-relative text-white fw-medium'>Reset Password</button>
          </div>
        </form>
      </div>
    </div>
  )
};

export default ResetPassword;