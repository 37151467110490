
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import React from 'react';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import Conditions from "./conditions";
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import avatar from '../../../assets/images/user_avatar.svg'
import outlineStar from '../../../assets/images/star-outline.svg'
import messageIcon from '../../../assets/images/message.svg'
import phoneIcon from '../../../assets/images/phone.svg'
import facebookIcon from '../../../assets/images/facebook.svg'
import emailIcon from '../../../assets/images/email-icon.svg'
// import outlineStar from '../../assets/images/star-outline.svg'


// import heartIcon from '../../../src/assets/images/heart.svg';
// import heartFilledIcon from '../../../src/assets/images/heart-filled.svg';
// import shareIcon from '../../../src/assets/images/share.svg';
// import shareIconBlack from '../../../src/assets/images/share-black.svg';
// import heartIconBlack from '../../../src/assets/images/heart-black.svg';
// import flagIconBlack from '../../../src/assets/images/flag-black.svg';

const MySwal = withReactContent(Swal);

const UserProfile = () => {
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [saved, setIsSaved] = useState(false);
  const { id } = useParams();

  const getUser = async (userId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/show-user`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json'
        }
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      setUser(data)
    } catch (error) {
      //  setError(error)
    }
  }

  const copyProductLink = async (productId) => {
    try {
      await navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL}/product/${productId}`);
      MySwal.fire({
        title: 'Link Copied to clipboard!!!'
      })
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }


  const saveProduct = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${user.id}/add-to-favourite`
    try {
      const response = await fetch(url, {
        method: "get",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
          "authorization": localStorage.getItem("token")
        }
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      setIsSaved(data.status === 'added')
    } catch (error) {
      //  setError(error)
    }
  }

  // const incrementProductViewCount=async (product_id)=> {
  //   const url=`${process.env.REACT_APP_BACKEND_URL}/products/${product_id}/increment-product-view-count`
  //   try{
  //       const response=await fetch(url, {
  //           method: "get",
  //           headers: {
  //               'content-type': 'application/json',
  //               'accept': 'application/json',
  //           }
  //       })
  //       const data=await response.json()
  //       if(!response.ok){
  //         setError(data.message)
  //         throw data.error
  //       }    
  //       setIsSaved(data.status === 'added')
  //   }catch(error){
  //     //  setError(error)
  //   }
  // }



  useEffect(() => {
    getUser(id);
    // incrementProductViewCount(id);
  }, [id]);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-4">
          <div className="d-flex">
            <img src={user && user.user_avatar_url ? user.user_avatar_url : avatar} alt={user?.title} style={{ height: '100px', width: '100px', borderRadius: '50%' }} />
            <div className='ms-3'>
              <strong>{user && user.name}</strong>
              <br />
              <span>Joined on {user && user.joined_on}</span>
              <br />
              {
                user && user.location ?
                  <div>
                    <span>{user && user.location}</span>
                    <br />
                  </div> : ''
              }
              <span className='d-flex align-items-center gap-1'>
                <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                <img src={outlineStar} alt='star' style={{ height: '20px', width: '20px' }} />
                <span className='fw-bold fs-5'>(0)</span>
              </span>
            </div>
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <div className='text-center'>
              <strong>0</strong>
              <br />
              <span>Bought</span>
            </div>
            <div className='text-center'>
              <strong>0</strong>
              <br />
              <span>Sold</span>
            </div>
            <div className='text-center'>
              <strong>0</strong>
              <br />
              <span>Followers</span>
            </div>
          </div>
          {/* <hr style={{margin: '20px 0px'}} /> */}
        </div>
        <div className='col-8'>
          <strong>Responds in few minutes</strong>
          <div className='d-flex mt-3' style={{ gap: '20px' }}>
            <div className='d-flex flex-column align-items-center'>
              <div style={{ borderRadius: "50%", padding: '5px', backgroundColor: "rgb(63,148,195)" }}>
                <img src={messageIcon} alt='message icon' style={{ height: '20px', width: '20px' }} />
              </div>
              <span>0% reply rate</span>
            </div>
            <div className='d-flex flex-column align-items-center'>
              <div style={{ borderRadius: "50%", padding: '5px', backgroundColor: "rgb(63,148,195)" }}>
                <img src={phoneIcon} alt='phone icon' style={{ height: '20px', width: '20px' }} />
              </div>
              <span>{user && user.phone ? 'Confirmed Phone' : 'Phone not confirmed'}</span>
            </div>
            <div className='d-flex flex-column align-items-center'>
              <div style={{ borderRadius: "50%", padding: '5px', backgroundColor: "rgb(63,148,195)" }}>
                <img src={facebookIcon} alt='message icon' style={{ height: '20px', width: '20px' }} />
              </div>
              <span>{user && user.proider === "facebook" ? "Confirmed Facebook" : "Facebook not confirmed"}</span>
            </div>
            <div className='d-flex flex-column align-items-center'>
              <div style={{ borderRadius: "50%", padding: '5px', backgroundColor: "rgb(63,148,195)" }}>
                <img src={emailIcon} alt='email icon' style={{ height: '20px', width: '20px' }} />
              </div>
              <span>{user && user.confirmed ? "Email confirmedd" : "Email not confirmed"}</span>
            </div>
          </div>
          <hr style={{ margin: '20px 0px' }} />
          <h2>Compliments</h2>
          <div className="d-flex gap-4">
            <div className='d-flex gap-2 align-items-center'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>Items as described</span>
            </div>
            <div className='d-flex gap-2'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>Friendly</span>
            </div>
            <div className='d-flex gap-2'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>On time</span>
            </div>
            <div className='d-flex gap-2'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>Reliable</span>
            </div>
            <div className='d-flex gap-2'>
              <span style={{ backgroundColor: "#e4e4e4", borderRadius: '21px', padding: '0px 10px', color: 'black' }}>0</span>
              <span>Communicative</span>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ margin: '20px 0px' }} />
      <div className='row'>
        <div>
          <h4 style={{ fontWeight: '800' }}>Items from the seller</h4>
          <div className='d-flex gap-4'>
            {
              user && user.products.map(sp => {
                let [id, url, title, price] = sp
                return <Link to={`/product/${id}`} >
                  <img src={url} alt={sp.title} style={{ height: '100px', width: '100px' }} />
                  <strong>{title}</strong>
                  <br />
                  <span>${price}</span>
                </Link>
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default UserProfile;