import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import enJSON from './locale/en.json'
import bzJSON from './locale/bz.json'

i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
    bz: { ...bzJSON },
  }, // Where we're gonna put translations' files
  lng: "en" // Set the initial language of the App
})
