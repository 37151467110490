const Logout =({setCurrUser})=>{
  const logout=async (setCurrUser)=>{
      try {
          const response=await fetch(`${process.env.REACT_APP_BACKEND_URL}/logout`,{
              method: "delete",
              headers: {
                  "content-type": "application/json",
                  "authorization": localStorage.getItem("token")
              },
          })
          const data=await response.json()
          if(!response.ok) throw data.error
          localStorage.removeItem("token")
          setCurrUser(null)
      } catch (error) {
          setCurrUser(null)
      }
  }
  const handleClick=e=>{
      e.preventDefault()
       logout(setCurrUser)
       window.location.href = "/";
  }
  return (
      <div>
          <a href="#" className="dropdown-item" onClick={handleClick}>Logout</a>
      </div>
  )
}
export default Logout