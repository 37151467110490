
import { useRef, useState } from 'react';
import offerup from './../../assets/images/offerup.svg'

const VerifyOtp = function ({ show, hide, onChangeContent, onHide, setCurrUser }) {
  const formRef = useRef();
  const [error, setError] = useState(null);

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { code: data.code }
    }
    sendCode(userInfo)
    // login(userInfo, setCurrUser)
    // e.target.reset()
  }

  const sendCode = async (userInfo) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/verify-otp`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          "authorization": localStorage.getItem("token")
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.message
      }
      setCurrUser(data.user)
      onHide();
    } catch (error) {
      setError(error)
    }
  }

  return (
    <>
      {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="d-flex justify-content-center dddd">
          <img src={offerup} alt='offerup logo' />
        </div>
        <div className="mt-4">
          <div className="mt-2">
            <label className='fw-medium'>OTP</label>
            <input type='text' name='code' className='form-control w-100' autoFocus={true} required={true} />
          </div>

          <div className="actions mt-2 gap-3 d-flex">
            <button type="submit" className="btn btn-outline-light border border-1 w-100 bg-green rounded-pill position-relative text-white fw-medium">Verify</button>
          </div>
        </div>
      </form>
    </>
  )
}

export default VerifyOtp;