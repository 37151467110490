
import offerup_img from '../assets/images/offerup.svg';
import email_icon from '../assets/images/email.svg';
import google_icon from '../assets/images/google.svg';
// import { GoogleAPI, GoogleLogin, GoogleLogout } from "react-google-oauth";
// import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useGoogleLogin } from "@react-oauth/google";
import axios from 'axios';

function SelectLogin({ show, hide, setCurrUser, onHide, onChangeContent, ...props }) {

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        }).then((res) => {
          saveGoogleUser(res.data)
        }).catch((err) => { });
    },
    onError: (error) => { }
  });

  async function saveGoogleUser(userInfo) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/create-google-user`
    try {
      const response = await fetch(url, {
        method: 'post',
        headers: {
          "content-type": 'application/json',
          "accept": "application/json"
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        // setError(data.message)
        throw data.error
      }
      localStorage.setItem("token", response.headers.get("Authorization"))
      setCurrUser(data)
      onHide();
    } catch (error) {
      // setError(error)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <img src={offerup_img} alt='logo' />
      </div>
      <div className="mt-4">
        <button className="btn btn-outline-light border border-1 w-100 text-green rounded-pill position-relative" onClick={() => onChangeContent('signupLogin')}>
          <img src={email_icon} className='position-absolute h-24 left-2' alt='email icon' style={{ fill: '#00a87e' }} />
          <span className="text-green fw-medium">Continue with email</span>
        </button>

        {/* <GoogleAPI className="GoogleLogin" clientId={"382554376301-idsamlvi7j9hmcb2clq3sks8jota8k0s.apps.googleusercontent.com"}>
          <div>
            <GoogleLogin
              height="10"
              width="500px"
              backgroundColor="#4285f4"
              access="offline"
              scope="email profile"
              onLoginSuccess={responseGoogle}
              onFailure={responseGoogle}
            />
          </div>
        </GoogleAPI> */}
        <button className="btn btn-primary bg-blue border border-1 w-100 text-green rounded-pill position-relative mt-2" onClick={() => googleLogin()}>
          {/* <GoogleOAuthProvider clientId={"382554376301-idsamlvi7j9hmcb2clq3sks8jota8k0s.apps.googleusercontent.com"}>
            <GoogleLogin
              onSuccess={credentialResponse => {
              }}
              onError={() => {
              }}
            />
            
          </GoogleOAuthProvider> */}
          <img src={google_icon} className='position-absolute h-24 left-2 bg-white rounded-circle' alt='google icon' />
          <span className="text-green text-white">Continue with Google</span>
        </button>
        <div className="text-center mt-3" style={{ color: "#505050" }} >
          <p className="mb-2" style={{ fontSize: '0.9375rem', fontWeight: '400', textAlign: 'center' }} >By tapping Continue with Facebook, Google, or Apple you agree to OfferUp's <strong className="text-underline"><a href="/">Terms of Service</a></strong> and acknowledge the Privacy Policy.</p>
          <p>This site is protected by reCAPTCHA and the Google Privacy Policy and the Google Terms of Service apply.</p>
        </div>
      </div>
    </>
  );
}

export default SelectLogin;