
import { useState,useEffect, useContext, useRef } from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import SearchIcon from '../assets/images/search.svg'
import { Link } from 'react-router-dom'

import logo  from '../assets/images/offerup.svg'
import downArrow from '../assets/images/down-arrow.svg'
import avatar from '../assets/images/user_avatar.svg'
import Logout from "./Logout";
import heartIconBlack from '../../src/assets/images/heart-black.svg';
import heartIconWhite from '../../src/assets/images/heart-white.svg';
import InboxIcon from '../../src/assets/images/inbox.svg';
import tagIcon from '../../src/assets/images/tag.png';
import tagIconWhite from '../../src/assets/images/tag-white.svg';
import ThemeContext from "../context/ThemeContext";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from './../assets/images/user_avatar.svg';
import cable from "../cable";

const OfferupNavbar=({currUser, onClickLogin, cityName, searchValue, switchLanguage})=>{
	const dropdownRef = useRef();
	const [unread, setUnread] = useState(false);
 	// const [messages, setMessages] = useState([]);
	const [inboxes, setInboxes] = useState([]);
	const [notifications, setNotifications] = useState([]);
	const [messageLoader, setMessageLoader] = useState(true);
	const [notificationLoader, setNotificationLoader] = useState(true);

	useEffect(() => {
		setUnread(currUser?.has_unread);
		const channel = cable.subscriptions.create(
      { channel: 'NotificationsChannel', user_id: currUser?.id },
      {
        received: (message) => {
					setUnread(true);
          // setMessages(prevMessages => [...prevMessages, message]);
        }
      }
    );
    return () => {
      channel.unsubscribe();
    };
	}, [currUser])

	async function fetchMessages(event){
		event.preventDefault();
		if(!document.querySelector("#inbox").classList.contains("show")) {
			try {
        const response=await fetch(`${process.env.REACT_APP_BACKEND_URL}/inboxes/get-inboxes`, {
            method: "get",
            headers: {
                "content-type": "application/json",
                "authorization": localStorage.getItem("token")
            }
        })
        if(!response.ok) throw Error
        const data=await response.json()
				setMessageLoader(false);
        setInboxes(data.data)
				let has_unread = false;
				data.data.forEach(function(inbox){
					if(inbox.has_unread){
						has_unread = true;
					}
				})
				setUnread(has_unread);
				// setUnread(false);
        if(data.avatar_url) {
          // setImageUrl(data.avatar_url)
        }
			}
			catch(error){
				// setUser(null);
			}

			// try {
      //   const response=await fetch(`${process.env.REACT_APP_BACKEND_URL}/messages`, {
      //       method: "get",
      //       headers: {
      //           "content-type": "application/json",
      //           "authorization": localStorage.getItem("token")
      //       }
      //   })
      //   if(!response.ok) throw Error
      //   const data=await response.json()
			// 	setMessageLoader(false);
      //   setMessages(data)
			// 	setUnread(false);
      //   if(data.avatar_url) {
      //     // setImageUrl(data.avatar_url)
      //   }
			// }
			// catch(error){
			// 	// setUser(null);
			// }
		}
	}

	const hideDropdown = (inbox_id) => {
		let has_unread = false;
		inboxes.filter(inbox => inbox.id !== inbox_id).forEach(function(inbox){
			if(inbox.has_unread) {
				has_unread = true;
			}
		})
		setUnread(has_unread);
		setTimeout(function() {
			dropdownRef.current.classList.toggle("show");
			document.getElementById("dropdown-autoclose-outside").classList.toggle("show");
			document.getElementById("dropdown-menu").classList.toggle("show");
		}, 100)
	}

	const {theme, setTheme} = useContext(ThemeContext);
  return(
  	<nav className={`navbar navbar-expand-lg p-0 ${theme === 'dark' ? 'bg-dark text-light' : ''}`} id="navbar" data-bs-theme={theme}>
		  <div className="container-fluid">
				<Link to='/' className="navbar-brand">
					<img src={logo} alt="logo" />
				</Link>
		    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
		      <span className="navbar-toggler-icon"></span>
		    </button>
		    <div className="collapse navbar-collapse" id="navbarText">
					<form style={{display: 'flex', width: '30%', alignItems: 'center', gap: 8}} className="navbar-nav me-auto mb-2 mb-lg-0">
						<div className="d-flex" style={{width: '70%'}}>
							<input type="text" placeholder="Search" className="search-input" name="search" defaultValue={searchValue} />
							<button className="search-btn">
								<img src={SearchIcon} alt="Search" style={{height: '32px', width: '32x'}}/>
							</button>
						</div>
						<strong className="text-green">{cityName}</strong>
					</form>
		      <span className="navbar-text">
		        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{display: 'flex', alignItems: 'center'}}>
		          { currUser ?
								<>
									<Dropdown ref={dropdownRef} className="d-inline mx-2" autoclose="outside" id="inbox" onClick={fetchMessages}>
										<Dropdown.Toggle id="dropdown-autoclose-outside">
											<div className="d-flex align-items-end" autoclose="outside">
												<span className="nav-item">
													<div to="" className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center">
														<div className="d-flex align-items-center" style={{gap: '4px'}}>
															<img alt="inbox icon" src={theme === "dark" ? InboxIcon : InboxIcon}  style={{height: '20px', width: '20px'}}/>
															{ unread &&
																<div style={{height: '10px', width: '10px', borderRadius: '50%', background: 'red'}}></div>
															}
														</div>
														<span>Inbox</span>
													</div>
												</span>
												<img src={downArrow} style={{height: '16px', width: '16px'}} alt="down-arrow" />
											</div>
										</Dropdown.Toggle>

										<Dropdown.Menu id="dropdown-menu" align="end" style={{width: '400px',right: 0, left: 'auto'}}>
											<ul class="nav nav-pills mb-3 d-flex justify-content-around" id="pills-tab" role="tablist">
												<li class="nav-item" role="presentation">
													<div class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Messages</div>
												</li>
												
												{ false &&
													<li class="nav-item" role="presentation">
													<div class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Notifications</div>
												</li>
												}
											</ul>
											<div class="tab-content" id="pills-tabContent">
												<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
													{
														messageLoader && <div class="spinner-border" role="status">
															<span class="visually-hidden">Loading...</span>
														</div>
													}
													{
														inboxes.length === 0 ? <span>No Messages</span> : <div>
															{
																inboxes && inboxes.map(function(inbox) {
																	return <Link onClick={()=> hideDropdown(inbox.id)} to={`/inbox/${inbox.id}/messages`} style={{textDecoration: 'none'}} className="d-flex justify-content-between align-items-start gap-2 border-bottom p-2 hover-bg-gray" key={inbox.id}>
																		<img style={{height: '50px', width: '50px', borderRadius: '50%'}} src={inbox.user_avatar_url ? inbox.user_avatar_url : userAvatar} alt="user avatar" />
																		<div style={{flex: 1}}>
																			<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
																				<strong>{inbox.user}</strong>
																				{ inbox.has_unread && <div style={{height: '10px', width: '10px', borderRadius: '50%', background: 'red'}}></div>}
																			</div>
																			<span>{inbox.message}</span>
																			<br />
																			<span style={{color: 'gray', fontSize: '13px'}}>{inbox.message_created_at}</span>
																		</div>
																		<img style={{height: '50px', width: '50px'}} src={inbox.product_image} alt="product img" />
																	</Link>
																})
															}
															{ false && <Link to={"/inbox"} style={{textDecoration: 'none'}}><strong className="text-green">View all messages</strong></Link> }
														</div>
													}
												</div>
												<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
													<div class="spinner-border" role="status">
														<span class="visually-hidden">Loading...</span>
													</div>
												</div>
											</div>
											{/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
											{/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
											{/* <Dropdown.Item href="#">Menu Item</Dropdown.Item> */}
										</Dropdown.Menu>
									</Dropdown>
									{/* <div className="dropdown">
										<div className="d-flex align-items-end" role="button" autoclose="outside" data-bs-toggle="dropdown" aria-expanded="false">
											<span className="nav-item">
												<div to="" className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center">
													<img src={theme === "dark" ? InboxIcon : InboxIcon}  style={{height: '20px', width: '20px'}}/>
													<span>Inbox</span>
												</div>
											</span>
											<img src={downArrow} style={{height: '16px', width: '16px'}}/>
										</div>
										<div className="dropdown-menu me-2 pull-right" style={{width: '400px',right: 0, left: 'auto'}}>
											<ul class="nav nav-pills mb-3 d-flex justify-content-around" id="pills-tab" role="tablist">
												<li class="nav-item" role="presentation">
													<div class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Messages</div>
												</li>
												<li class="nav-item" role="presentation">
													<div class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Notifications</div>
												</li>
											</ul>
											<div class="tab-content" id="pills-tabContent">
												<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">Messages will go here</div>
												<div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">Notifications will go here</div>
											</div>
										</div>
									</div> */}
									<span className="nav-item">
										<Link to="/saved_products" className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center" style={{textDecoration: 'none'}}>
											<img src={theme === "dark" ? heartIconWhite : heartIconBlack}  style={{height: '16px', width: '16px'}}/>
											<span>Saved</span>
										</Link>
									</span>
									<span className="nav-item">
										<Link to="/my-items" className="mx-2 d-flex flex-column cursor-pointer pointer align-items-center"  style={{textDecoration: 'none'}}>
											<img src={theme === "dark" ? tagIconWhite : tagIcon}  style={{height: '20px', width: '20px'}}/>
											<span>My Items</span>
										</Link>
									</span>
									<span className="nav-item">
			            	<Link to="/sell-item">
											<button style={{border: 'none', color: 'white', borderRadius: '10px', padding: '5px 15px', background: 'green'}}>Sell Item</button>
										</Link>
			            </span>
									<span className="nav-item">
			            	<a href="#" className="nav-link">{currUser.email}</a>
			            </span>
									<div className="dropdown">
										<div className="d-flex align-items-end" role="button" data-bs-toggle="dropdown" aria-expanded="false">
											<img src={currUser.avatar_url ? currUser.avatar_url : avatar} style={{height: '40px', width: '40px'}}/>
											<img src={downArrow} style={{height: '16px', width: '16px'}}/>
										</div>
										<div className="dropdown-menu me-2 pull-right" style={{width: '288px',right: 0, left: 'auto'}}>
											<li>
												<div className="dropdown-item">
													<Link to={`/user/${currUser?.id}`} style={{textDecoration: 'underline', color: 'black', display: 'flex', alignItems: 'center'}}>
														<div href="#" className="d-flex align-items-center gap-2 text-decoration-none">
															<div>
																<img src={currUser.avatar_url ? currUser.avatar_url : avatar} style={{height: '60px', width: '60px'}}/>
															</div>
															<div className="d-flex flex-column ms-2">
																<strong style={{fontSize: '13px'}}>{currUser.email}</strong>
																<span>View public profile</span>
															</div>
														</div>
													</Link>
												</div>
											</li>
											<li>
												<Link to="/account-settings" className="dropdown-item">Account Settings</Link>
											</li>
											<li>
												<Logout /> 
												{/* <a href="#" className="dropdown-item">Account Settings</a> */}
												{/* <%#= link_to "Log out", destroy_user_session_path, data: { turbo_method: :delete }, class: 'dropdown-item' %> */}
											</li>
										</div>
									</div>
								</>
										: (
			            <li className="nav-item">
		              	<span className="nav-link" style={{cursor: 'pointer'}} onClick={onClickLogin}>Login</span>
		            	</li>
		            )
		          }
							<select onChange={switchLanguage}>
								<option value="en">EN</option>
								<option value="bz">BZ</option>
							</select>
						</ul>
		      </span>
		    </div>
		  </div>
		</nav>
  )
}
export default OfferupNavbar