
import { useRef, useState } from "react"

function Login({ currUser, setCurrUser, onHide, onChangeContent }) {
  const formRef = useRef();
  const [error, setError] = useState(null);
  const login = async (userInfo, setCurrUser) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/login`
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify(userInfo)
      })
      const data = await response.json()
      if (!response.ok) {
        setError(data.message)
        throw data.error
      }
      localStorage.setItem("token", response.headers.get("Authorization"))
      setCurrUser(data)
      if (data.phone === "" || data.phone == null) {
        if (!data.skip_phone) {
          onChangeContent('verify_phone');
        } else {
          onHide();
        }
      } else {
        onHide();
      }
    } catch (error) {
      setError(error)
    }
  }
  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)
    const userInfo = {
      "user": { email: data.email, password: data.password }
    }
    login(userInfo, setCurrUser)
    e.target.reset()
  }
  return (
    <>
      {error && <div className="alert alert-danger p-2" role="alert">{error}</div>}
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="mt-2">
          <label className="fw-medium">Email</label>
          <input type="email" name="email" className="form-control w-100" />
          <span className="feedback"></span>
        </div>

        <div className="mt-2">
          <label className="fw-medium">Password</label>
          <input type="password" name="password" className="form-control w-100" />
          <span className="feedback"></span>
        </div>

        <div>
          <span style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onChangeContent('forgot_password')}>Forgot Password?</span>
        </div>

        <div className="text-center mt-3" style={{ color: '#505050', fontSize: '0.9375rem', 'fontWeight': 400, textAlign: 'center' }}>
          <p>By tapping "Agree & Log in" you agree to OfferUp's Terms of Service and acknowledge the Privacy Policy.</p>
        </div>

        <div className="actions">
          <button type="submit" className="btn btn-outline-light border border-1 w-100 bg-green rounded-pill position-relative text-white fw-medium">Agree & Log in</button>
        </div>
      </form>
    </>
  );
}

export default Login;