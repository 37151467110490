

const Alert = function(type, message) {
  if (type === 'success') {
    return <div class="alert alert-success" role="alert">
            {message}
          </div>
  }else if (type === 'error') {
    return <div className="alert alert-danger" role="alert">
      {message}
    </div>
  }
}

export default Alert;