

// App.js
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from "./components/Home.jsx";
import SellItem from './components/Product/SellItem.jsx';
import Navbar from './components/Navbar.js'

import User from './components/User.js'
import NavBar from './components/Navbar.js';
import OfferupModal from './components/Modals/OfferupModal.jsx';
import SelectLogin from './components/SelectLogin.jsx';
import SignupLogin from './components/SignupLogin.jsx';
import Signup from './components/Signup.jsx';
import Login from './components/Login.jsx'
import Categories from './components/Categories.jsx';
import AccountSettings from './components/Product/User/AccountSettings.jsx';
import VerifyPhone from './components/Auth/VerifyPhone.jsx';
import VerifyOtp from './components/Auth/VerifyOtp.jsx';
import Confirmation from './components/Auth/Confirmation.jsx';
import ForgotPassword from './components/Auth/ForgotPassword.jsx';
import ResetPassword from './components/Auth/ResetPassword.jsx';
import ShowProduct from './components/Product/ShowProduct.jsx';
import Alert from './components/Modals/Alert.jsx'

import { useSearchParams } from 'react-router-dom';
import SavedProduct from './components/Product/SavedProduct.jsx';
import MyItems from './components/Product/MyItems.jsx';
import ArchivedItems from './components/Product/ArchivedItems.jsx';
import { useTranslation } from "react-i18next";
// import About from './Pages/About';
// import Products from './Pages/Products';

import ThemeContext from "./context/ThemeContext.jsx"
import UserProfile from './components/Product/User/UserProfile.jsx';
import NotificationSettings from './components/Product/User/NotificationSettings.jsx';
import Transactions from './components/Product/User/Transactions.jsx';
import Payments from './components/Product/User/Payments.jsx';
import Inbox from './components/Product/User/Inbox.jsx';
import Messages from './components/Product/User/Messages.jsx';

const App = () => {
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language)
  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "bz" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
  }

  const [theme, setTheme] = useState(localStorage.getItem('theme') || "light");
  const [searchParams, setSearchParams] = useSearchParams();
  let search = searchParams.get('search');

  const [currUser, setCurrUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState('selectLogin');
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [city, setCity] = useState('');
  const [alert, showAlert] = useState(false);
  let showGobackBtn = false;
  let goBackContent = '';
  let modalContent = '';
  let modalTitle = '';
  if (content === 'selectLogin') {
    modalTitle = 'Sign up / Log in';
    modalContent = <SelectLogin currUser={currUser} setCurrUser={setCurrUser} onHide={hideModal} onChangeContent={handleSetContent} />
  } else if (content === 'signupLogin') {
    modalTitle = 'Sign up / Log in';
    modalContent = <SignupLogin onChangeContent={handleSetContent} />
  } else if (content === 'signup') {
    goBackContent = 'signupLogin';
    showGobackBtn = true;
    modalTitle = 'Sign up';
    modalContent = <Signup currUser={currUser} setCurrUser={setCurrUser} onHide={hideModal} onChangeContent={handleSetContent} />
  } else if (content === 'login') {
    showGobackBtn = true;
    goBackContent = 'signupLogin';
    modalTitle = 'Log in';
    modalContent = <Login currUser={currUser} setCurrUser={setCurrUser} onChangeContent={handleSetContent} onHide={hideModal} />
  } else if (content === 'verify_phone') {
    showGobackBtn = false;
    goBackContent = 'login';
    modalTitle = 'Verify Phone';
    modalContent = <VerifyPhone currUser={currUser} setCurrUser={setCurrUser} onChangeContent={handleSetContent} onHide={hideModal} />
  } else if (content === 'verify_otp') {
    showGobackBtn = false;
    goBackContent = 'login';
    modalTitle = 'Verify Phone';
    modalContent = <VerifyOtp currUser={currUser} setCurrUser={setCurrUser} onChangeContent={handleSetContent} onHide={hideModal} />
  } else if (content === 'forgot_password') {
    showGobackBtn = true;
    goBackContent = 'login';
    modalTitle = 'Forgot Password';
    modalContent = <ForgotPassword currUser={currUser} setCurrUser={setCurrUser} onChangeContent={handleSetContent} onHide={hideModal} />
  }

  async function fetchCategories() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/categories`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          // "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setCategories(data)
    }
    catch (error) {
      setCategories([])
    }
  }

  useEffect(() => {
    theme === "dark" ?
      (
        document.body.classList.add("bg-dark", "text-light")
      ) :
      document.body.classList.remove("bg-dark", "text-light")
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== undefined) {
      getText();
    }
    fetchCategories();
    getLatLngLocation();
  }, [theme])

  const getLatLngLocation = () => {
    const fetchNearestAddress = async (latitude, longitude) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/get-location-by-lat-lng?lat=${latitude}&lng=${longitude}`)
        if (!response.ok) {
          throw new Error('Failed to fetch nearest address');
        }
        const data = await response.json();
        setCity(data.cidade.nome)
      } catch (error) {
        setError(error.message);
      }
    }

    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            // setCurrentLocation({ latitude, longitude });
            fetchNearestAddress(latitude, longitude);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    }

    getCurrentLocation();
  }

  const getText = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/get-user`, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "authorization": localStorage.getItem("token")
        }
      })
      if (!response.ok) throw Error
      const data = await response.json()
      setCurrUser(data)
    }
    catch (error) {
    }
  }

  function openModal() {
    setShowModal(true);
  }

  function hideModal() {
    setShowModal(false);
    setContent('selectLogin');
  }

  function handleSetContent(content) {
    setContent(content);
  }

  function displayAlert(show, type, message) {

  }



  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {showModal &&
        <OfferupModal
          showGoBack={showGobackBtn}
          show={showModal}
          hide={hideModal}
          title={modalTitle}
          goBackContent={goBackContent}
          onChangeContent={handleSetContent}
        >
          {modalContent}
        </OfferupModal>
      }

      <Navbar currUser={currUser} onClickLogin={openModal} cityName={city} searchValue={search} switchLanguage={handleChangeLanguage} />
      <Categories categories={categories} />
      {showAlert && <Alert />}
      <Routes>
        <Route path="/sell-item" element={<SellItem />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/notification-settings" element={<NotificationSettings />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/accounts/transactions" element={<Transactions />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/accounts/payments" element={<Payments />} />
        <Route path="/inbox/:id/messages" element={<Messages currUser={currUser} />} />
        {/* <Route path="/auth/google/callback" element={<Confirmation />} /> */}
        <Route path="/password/edit" element={<ResetPassword />} />
        <Route path="/product/:id" element={<ShowProduct currentUser={currUser} />} />
        <Route path="/saved_products" element={<SavedProduct />} />
        <Route path="/my-items" element={<MyItems />} />
        <Route path="/archived-products" element={<ArchivedItems />} />
        <Route path="/user/:id" element={<UserProfile />} />
        <Route path="/" element={<Home searchValue={search} />} />
      </Routes>
    </ThemeContext.Provider>
  );
};

export default App;