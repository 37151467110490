import { useState, useEffect } from 'react';
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import "../App.css";
import ListProducts from './Product/ListProducts.jsx';
import Sidebar from './Sidebar.jsx';
import { useSearchParams } from 'react-router-dom';

const Home = ({ searchValue }) => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState(null);

  let min = searchParams.get('min');
  let max = searchParams.get('max');
  let condition = searchParams.get('condition');



  useEffect(() => {
    // getText();
    getLatLngProducts();
  }, [])

  const getLatLngProducts = () => {
    const fetchNearestProducts = async (latitude, longitude) => {
      try {
        let queryStr = `?min=${min}&max=${max}&condition=${condition}&lat=${latitude}&lng=${longitude}&search=${searchValue}&page=${page}`;
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products${queryStr}`, {
          method: "get",
          headers: {
            "content-type": "application/json",
            // "authorization": localStorage.getItem("token")
          }
        })
        if (!response.ok) throw Error
        const data = await response.json()
        // setProducts(data)
        setProducts((prevProducts) => [...prevProducts, ...data.products]);
        setPage((prevPage) => prevPage + 1);
        setHasMore(data.current_page < data.total_pages);
      }
      catch (error) {
        setProducts([])
      }
    }

    const getCurrentLocation = () => {
      // showLoader(true)
      let lat = localStorage.getItem("lat");
      let lng = localStorage.getItem("lng");
      if (lat != null && lng != null) {
        fetchNearestProducts(lat, lng);
      } else {
        fetchNearestProducts()
        // if (navigator.geolocation) {
        //   navigator.geolocation.getCurrentPosition(
        //     (position) => {
        //       const latitude = position.coords.latitude;
        //       const longitude = position.coords.longitude;
        //       localStorage.setItem("lat", latitude);
        //       localStorage.setItem("lng", longitude);
        //       // setCurrentLocation({ latitude, longitude });
        //       fetchNearestProducts(latitude, longitude);
        //     },
        //     (error) => {
        //     }
        //   );
        // } else {
        // }
      }
    }

    getCurrentLocation();
  }

  // const getProducts=async ()=>{
  //   try {
  //     // navigator.geolocation.getCurrentPosition((position) => {

  //     // })
  //     let queryStr = `?min=${min}&max=${max}&condition=${condition}`;
  //     const response=await fetch(`${process.env.REACT_APP_BACKEND_URL}/products${queryStr}`, {
  //         method: "get",
  //         headers: {
  //             "content-type": "application/json",
  //             // "authorization": localStorage.getItem("token")
  //         }
  //     })
  //     if(!response.ok) throw Error
  //     const data=await response.json()
  //     setProducts(data)
  //   }
  //   catch(error){
  //     setProducts([])
  //   }
  // }

  return (
    <div className='mx-2'>
      <div className='row mt-5'>
        <Sidebar min={min} max={max} />
        <div className='col-10'>
          <ListProducts products={products} fetchProducts={getLatLngProducts} hasMore={hasMore} />
        </div>
      </div>
    </div>
  );
}
export default Home;